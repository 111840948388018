<template>
  <Dialog
    v-model:visible="visibleDialog"
    :style="{ width: '700px' }"
    header="Nova Imagem"
    :modal="true"
    @hide="hideDialog"
  >
    <div v-if="showImg" class="p-fluid formgrid">
      <div
        v-if="object.identifierFile != null"
        class="field col-12"
        align="center"
      >
        <img
          :src="checkProviderImg(object.identifierFile)"
          width="200"
          height="300"
          class="img-fluid img-thumbnail"
        />
      </div>
      <div v-else>
        <p class="text-center">Você não possui nenhuma imagem.</p>
      </div>
    </div>
    <hr />
    <div v-if="file !== null" class="p-fluid formgrid grid">
      <div align="center" class="field col-12 md:col-6">
        <p>Imagem Carregada</p>
        <img v-if="cropImg" :src="cropImg" class="img-fluid" />
      </div>
      <div
        align="center"
        class="field col-12 md:col-6"
        v-if="this.object.gender"
      >
        <p>Área de Corte</p>
        <VueCropper
          ref="cropper"
          :src="cropImg"
          alt="FOTO"
          :aspect-ratio="3 / 4"
          class="img-fluid"
        />
      </div>
    </div>
    <FileUpload
      ref="fileUpload"
      name="demo[]"
      accept="image/*"
      invalidFileTypeMessage="Tipo de arquivo inválido, tipos de arquivo permitidos: PNG, JPEG"
      invalidFileSizeMessage="Tamanho de arquivo inválido, o tamanho do arquivo deve ser no máximo 15 MB."
      :chooseLabel="'Escolher'"
      :maxFileSize="18000000"
      @select="onSelect"
      :fileLimit="1"
      :showUploadButton="false"
      @clear="onCancel"
    >
      <template #empty>
        <p class="p-text-center">Nenhum arquivo selecionado</p>
      </template>
    </FileUpload>
    <ProgressBar mode="indeterminate" v-if="initProgress" class="mt-3" />
    <template #footer>
      <Button
        label="Salvar"
        class="p-button-success"
        icon="pi pi-check"
        v-if="file !== null"
        @click="send"
      />
      <Button
        label="Cortar"
        class="p-button"
        icon="pi pi-pencil"
        :disabled="cropImg === null"
        v-if="file !== null && this.object.gender"
        @click="crop"
      />
      <Button
        label="Excluir"
        class="p-button-danger"
        icon="pi pi-trash"
        v-if="object.identifierFile !== null"
        @click="removeFile"
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button p-button-text"
        @click="hideDialog"
      />
    </template>
  </Dialog>
</template>

<script>
//Components
import VueCropper from "vue-cropperjs";
import Compress from "compress.js";
import "cropperjs/dist/cropper.css";

export default {
  components: { VueCropper },
  props: [
    "objectSelected",
    "objectService",
    "showImg",
    "keyFolder",
    "stateDialog",
  ],
  data() {
    return {
      object: null,
      compress: new Compress(),
      submitted: false,
      file: null,
      cropImg: null,
      initProgress: false,
    };
  },
  computed: {
    visibleDialog: {
      get() {
        let value = this.stateDialog.dialogUpload;
        if (value === true) this.getData();
        return value;
      },
      set(value) {
        let objectDialog = this.stateDialog;
        objectDialog.dialogUpload = value;
      },
    },
  },
  methods: {
    onSelect(obj) {
      let fileReader = new FileReader();
      this.file = obj.files[0];

      this.compress
        .compress([this.file], {
          size: 2,
          quality: 0.75,
          maxWidth: 1920,
          maxHeight: 1920,
          resize: true,
        })
        .then((data) => {
          this.file.base64 = `${data[0].prefix}${data[0].data}`;
          /*carregando imagem */

          fileReader.onload = () => {
            this.file.base64 = fileReader.result;
            this.cropImg = this.file.base64;
            this.$refs.cropper.replace(this.file.base64);
          };
          fileReader.readAsDataURL(this.file);
          this.file = this.convertFile(
            `${data[0].prefix}${data[0].data}`,
            `${data[0].alt}`,
            `${data[0].ext}`
          );
        });
    },
    onCancel() {
      this.file = null;
      this.cropImg = "";
    },
    crop() {
      if (this.file === null) {
        this.$msgInfo("Nenhum arquivo,selecione um arquivo.");
        return;
      }
      let mimeType = this.file.type;
      let name = this.file.name;
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
      this.file = this.convertFile(this.cropImg, name, mimeType);
    },
    send() {
      /** SE NÃO TIVER IMAGEM CADASTRADA**/
      if (
        this.object.identifierFile === null ||
        this.object.identifierFile === ""
      ) {
        /** ENTÃO CADASTRA UMA NOVA IMAGEM**/
        this.uploadFile();
      } else {
        /** SE NÃO ATUALIZA UMA IMAGEM EXISTENTE PELO HASH MANDADO**/
        this.updateFile();
      }
    },
    uploadFile() {
      this.initProgress = true;
      this.$Storage
        .upload(this.file, this.keyFolder)
        .then((response) => {
          this.update(response.data.hashKey);
        })
        .catch((error) => {
          this.$msgErro(error);
          this.initProgress = false;
        });
    },
    updateFile() {
      this.initProgress = true;
      if (this.object.identifierFile.includes("firebase")) {
        this.uploadFile();
      } else {
        this.$Storage
          .update(this.object.identifierFile, this.file)
          .then((response) => {
            this.update(response.data.hashKey);
          })
          .catch((error) => {
            this.$msgErro(error);
            this.initProgress = false;
          });
      }
    },
    removeFile() {
      this.$confirm.require({
        message: "Realmente, você deseja excluir esse arquivo? ",
        header: "Atenção.",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        accept: () => {
          this.$Storage
            .remove(this.object.identifierFile)
            .then(() => {
              this.update(null);
            })
            .catch((error) => {
              this.$msgErro(error);
              this.initProgress = false;
            });
        },
      });
    },
    update(hashKey) {
      this.object.identifierFile = hashKey;
      this.objectService
        .update(this.object)
        .then((response) => {
          this.$msgSuccess(response);
          this.hideDialog();
          this.initProgress = false;
        })
        .catch((error) => {
          this.$msgErro(error);
        });
    },
    hideDialog() {
      this.submitted = false;
      this.object = null;
      this.cropImg = null;
      this.initProgress = false;
      this.file = null;
      //this.$emit("findAll");
      this.visibleDialog = false;
    },
    convertFile(base64, filename, mimeType) {
      if (base64.startsWith("data:")) {
        let arr = base64.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let file = new File([u8arr], filename, { type: mime || mimeType });
        //return Promise.resolve(file);
        return file;
      }
      return fetch(base64)
        .then((res) => res.arrayBuffer())
        .then((buf) => new File([buf], filename, { type: mimeType }));
    },
    checkProviderImg(param) {
      /* SE O PROVEDOR DA IMAGEM FOR O FIREBASE */
      if (param.includes("firebase")) {
        return param;
        /* SE O PROVEDOR DA IMAGEM FOR API DE STORAGE */
      } else {
        return this.$Storage.previewImg(param);
      }
    },
    getData() {
      this.object = this.objectSelected;
    },
  },
};
</script>
<style scoped></style>
